.conference-confirmation {
  min-height: 50vh;
}
@media (min-width: 800px) {
  .conference-confirmation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 50px 0 0;
  }
}
.conference-confirmation .sidebar {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 30%;
          flex: 1 0 30%;
}
@media (min-width: 800px) {
  .conference-confirmation .sidebar {
    padding-left: 50px;
  }
}
.conference-confirmation .main {
  -webkit-box-flex: 2;
      -ms-flex: 2 1 100%;
          flex: 2 1 100%;
}
