.estate-more-club-info {
  margin: 50px 5% 0;
}
@media (min-width: 800px) {
  .estate-more-club-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 50px 0 0;
  }
}
.estate-more-club-info .sidebar {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 30%;
          flex: 1 0 30%;
}
.estate-more-club-info .sidebar a {
  text-decoration: none;
  color: #c28b30;
}
.estate-more-club-info .sidebar a:hover {
  color: #d4a351;
}
@media (min-width: 800px) {
  .estate-more-club-info .sidebar {
    padding-left: 50px;
  }
}
.estate-more-club-info .sidebar .contact-information {
  margin-top: 25px;
}
.estate-more-club-info .sidebar .contact-information .single-person {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
}
.estate-more-club-info .sidebar .contact-information .single-person .contact-image {
  width: 80px;
  height: 87px;
  background-size: cover;
  background-repeat: no-repeat;
}
.estate-more-club-info .sidebar .contact-information .single-person .contact-image.jonas {
  background-image: url(/static/ec92b9e31ee121d432371ef07026e4f3.png);
}
.estate-more-club-info .sidebar .contact-information .single-person .contact-info {
  margin-left: 10px;
  -ms-flex-preferred-size: 70%;
      flex-basis: 70%;
}
.estate-more-club-info .sidebar .contact-information .single-person .contact-info p {
  margin: 0;
}
.estate-more-club-info .sidebar .contact-information .single-person .contact-info a {
  color: #c28b30;
  text-decoration: none;
}
.estate-more-club-info .main {
  -webkit-box-flex: 2;
      -ms-flex: 2 1 100%;
          flex: 2 1 100%;
}
.estate-more-club-info .main .more-club-plans {
  border-collapse: separate;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  margin-bottom: 0px;
}
.estate-more-club-info .main .more-club-plans th {
  border-radius: 7px 7px 0 0;
  padding: 6px 2px 8px;
  text-align: center;
  font-size: 13px;
  line-height: 1.45em;
  border-bottom: 1px solid #fff;
}
.estate-more-club-info .main .more-club-plans th strong {
  font-size: 13px;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
}
.estate-more-club-info .main .more-club-plans th:nth-child(2) {
  width: 74px;
}
.estate-more-club-info .main .more-club-plans th:nth-child(2) strong {
  color: #979899;
}
.estate-more-club-info .main .more-club-plans th:nth-child(3),
.estate-more-club-info .main .more-club-plans th:nth-child(5),
.estate-more-club-info .main .more-club-plans th:nth-child(7) {
  width: 7px;
  background-color: #fff;
}
.estate-more-club-info .main .more-club-plans th:nth-child(4) {
  width: 74px;
}
.estate-more-club-info .main .more-club-plans th:nth-child(4) strong {
  color: #CCA368;
}
.estate-more-club-info .main .more-club-plans th:nth-child(6) {
  width: 74px;
}
.estate-more-club-info .main .more-club-plans th:nth-child(6) strong {
  color: #A5ABBB;
}
.estate-more-club-info .main .more-club-plans th:nth-child(1),
.estate-more-club-info .main .more-club-plans td:nth-child(1),
.estate-more-club-info .main .more-club-plans th:nth-child(1),
.estate-more-club-info .main .more-club-plans td:nth-child(1) {
  border-bottom: 1px solid #bcbcbc;
  font-size: 13px;
  line-height: 1.45em;
  padding: 5px;
}
.estate-more-club-info .main .more-club-plans td {
  border-bottom: 1px solid #fff;
  position: relative;
}
.estate-more-club-info .main .more-club-plans td,
.estate-more-club-info .main .more-club-plans td * {
  font-size: 14px;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
}
.estate-more-club-info .main .more-club-plans td.check:before {
  content: "\2713";
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 20px;
}
.estate-more-club-info .main .more-club-plans td.minus:before {
  content: "-";
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
.estate-more-club-info .main .more-club-plans td:nth-child(2) {
  background-color: #E3E5E3;
  text-align: center;
}
.estate-more-club-info .main .more-club-plans td:nth-child(4) {
  background-color: #F1E3CC;
  text-align: center;
}
.estate-more-club-info .main .more-club-plans td:nth-child(6) {
  background-color: #E1E4EB;
  text-align: center;
}
.estate-more-club-info .main .more-club-plans td.members-only {
  padding-right: 75px;
}
.estate-more-club-info .main .more-club-plans td.members-only:before {
  position: absolute;
  content: "MEMBERS ONLY";
  text-align: center;
  background-color: #01a9e4;
  border-radius: 2px;
  color: #fff;
  right: 5px;
  top: 6px;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
  font-size: 9px;
  line-height: 12px;
  padding: 0 2px;
}
.estate-more-club-info .main .exVat {
  color: #000000;
  font-size: 15px;
  margin-bottom: 00px;
  font-style: italic;
}
.estate-more-club-info .main .more-club-plan {
  display: block;
  margin-top: 30px;
  margin-bottom: 0px;
}
@media (min-width: 800px) {
  .estate-more-club-info .main .more-club-plan {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.estate-more-club-info .main .more-club-plan .description {
  display: none;
  margin-top: 0px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
}
.estate-more-club-info .main .more-club-plan .description h3 {
  text-transform: uppercase;
  font-size: 21px;
  margin-bottom: 0;
}
.estate-more-club-info .main .more-club-plan .description p {
  margin-top: 5px;
}
.estate-more-club-info .main .more-club-plan .description .more-club-logo {
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(/static/e89834dba93f1f15a312835194c15c24.png);
}
@media (min-width: 800px) {
  .estate-more-club-info .main .more-club-plan .description {
    margin-right: 50px;
  }
}
.estate-more-club-info .main .more-club-plan table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  background-color: transparent;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 70%;
          flex: 1 1 70%;
  width: 100%;
}
.estate-more-club-info .main .more-club-plan table th {
  text-align: left;
}
.estate-more-club-info .main .more-club-plan table th,
.estate-more-club-info .main .more-club-plan table th * {
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
}
.estate-more-club-info .main .more-club-plan table th:nth-child(3) {
  width: 90px;
  border-radius: 7px 7px 0 0;
  padding: 6px 2px 2px;
  text-align: center;
  font-size: 13px;
  line-height: 1.45em;
}
.estate-more-club-info .main .more-club-plan table td {
  font-size: 13px;
  line-height: 1.45em;
  padding: 5px;
  background-color: #ecebe6;
  border-bottom: 3px solid #fff;
}
.estate-more-club-info .main .more-club-plan table td,
.estate-more-club-info .main .more-club-plan table td * {
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
}
.estate-more-club-info .main .more-club-plan table td p {
  margin: 0;
}
.estate-more-club-info .main .more-club-plan table td:nth-child(1) {
  width: 34px;
}
.estate-more-club-info .main .more-club-plan.silver table {
  display: none;
}
.estate-more-club-info .main .more-club-plan.silver table th:nth-child(3) {
  background-color: #bcbdbf;
}
.estate-more-club-info .main .more-club-plan.silver table td {
  background-color: #E3E5E3;
}
.estate-more-club-info .main .more-club-plan.gold table {
  display: none;
}
.estate-more-club-info .main .more-club-plan.gold table th:nth-child(3) {
  background-color: #f19737;
}
.estate-more-club-info .main .more-club-plan.gold table td {
  background-color: #F1E3CC;
}
.estate-more-club-info .main .more-club-plan.platin table {
  display: none;
}
.estate-more-club-info .main .more-club-plan.platin table th:nth-child(3) {
  background-color: #a5abbb;
}
.estate-more-club-info .main .more-club-plan.platin table td {
  background-color: #E1E4EB;
}
.estate-more-club-info .sign-up-link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.estate-more-club-info .sign-up-link-container .sign-up-link a {
  display: block;
  width: 300px;
  max-width: 300px;
  padding: 10px 0;
  background-color: #c28b30;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  color: white;
}
.estate-more-club-info .sign-up-link-container .sign-up-link a:hover {
  background-color: #d4a351;
}
.estate-more-club-info .sign-up-link-container .link-to-conditions a {
  text-decoration: none;
}
