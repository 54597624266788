.cover-image-frontpage {
  height: 250px;
  max-height: 50vh;
  background-color: #ececec;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-bottom: calc(40px +  50px);
  position: relative;
  background-image: url(/static/5c7bbcd9552b93609f7d2d24c26db993.jpg);
}
@media (min-width: 800px) {
  .cover-image-frontpage {
    height: 430px;
    margin-bottom: calc(40px +  50px);
  }
}
@media (min-width: 1200px) {
  .cover-image-frontpage {
    width: calc(100% +  100px * 2);
    margin-left: -100px;
    margin-bottom: calc(140px +  50px);
  }
}
.cover-image-frontpage .highlight-component {
  position: absolute;
  bottom: -50px;
  width: calc(100% - 20px * 2);
  left: 20px;
  height: 225px;
  background-color: #000000;
  overflow: hidden;
  line-height: 35px;
}
@media (min-width: 800px) {
  .cover-image-frontpage .highlight-component {
    width: calc(100% -  100px / 2 * 2);
    left: 50px;
    margin: auto;
  }
}
@media (min-width: 1200px) {
  .cover-image-frontpage .highlight-component {
    width: calc(100% -  100px * 2);
    left: 100px;
    top: 417px;
  }
}
