.conference-card {
  height: 270px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 33%;
          flex: 1 1 33%;
  margin: 15px 20px;
  cursor: pointer;
}
@media (min-width: 800px) {
  .conference-card {
    min-width: 31%;
    max-width: 50%;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .conference-card:first-child {
    margin-left: 0;
  }
  .conference-card:last-child {
    margin-right: 0;
  }
}
.conference-card .conference-image {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 45%;
          flex: 1 1 45%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.conference-card .conference-info {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 45%;
          flex: 1 1 45%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid #bab7b9;
  border-top-width: 0;
  border-bottom-width: 0;
  padding: 5px;
}
.conference-card .conference-info .conference-date {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.conference-card .conference-info .title-container .conference-title {
  text-transform: uppercase;
  margin: 0;
  font-size: 14px;
}
.conference-card .conference-info .title-container .conference-subtitle {
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
}
.conference-card .conference-info .details-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 10px;
  margin-right: 0px;
}
.conference-card .conference-info .details-container .conference-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.conference-card .conference-info .details-container .conference-details .details-title {
  font-weight: bold;
}
.conference-card .conference-info .details-container .conference-details p {
  margin: 0;
}
.conference-card .conference-info .details-container .conference-details p:first-child {
  margin-right: 5px;
}
.conference-card .conference-info .details-container .conference-details.host-details {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 13px;
  padding: 0px;
}
.conference-card .conference-info .details-container .conference-details.host-details.host-city {
  font-size: 15px;
  color: green;
}
.conference-card .conference-info .details-container .conference-details.host-details.host-host {
  position: absolute;
  right: 0;
  bottom: 0;
}
.conference-card .conference-info .details-container .conference-details.host-details .host-logo {
  margin-left: 8px;
  margin-bottom: -3px;
  max-height: 30px;
  max-width: 100px;
}
.conference-card .call-to-action {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 40px;
          flex: 0 1 40px;
  background-color: #bab7b9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.conference-card .call-to-action a {
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.conference-card .call-to-action a p {
  margin: 0;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  color: white;
  width: 100%;
  text-align: center;
}
.conference-card .call-to-action a:hover {
  background-color: #c28b30;
}
