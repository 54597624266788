.my-page-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.my-page-login form {
  width: 300px;
  border: 1px solid #bab7b9;
}
.my-page-login form > h3 {
  text-align: center;
}
.my-page-login form .input-fields {
  padding: 15px;
}
.my-page-login form .input-fields label {
  display: block;
}
.my-page-login form .input-fields input {
  display: block;
  width: 100%;
  border: 1px solid #bab7b9;
  padding: 5px 10px;
  margin-bottom: 10px;
}
.my-page-login form .forgot-password-link {
  font-size: 1.1em;
  text-align: center;
}
.my-page-login form .forgot-password-link a {
  text-decoration: none;
}
.my-page-login form .submit-button {
  width: 100%;
  background-color: #c28b30;
  text-align: center;
  padding: 10px 0;
  margin: 0;
  color: white;
  font-size: 16px;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
}
.my-page-login form .submit-button:hover {
  background-color: #d4a351;
}
.helptext {
  margin: 20px 20px 20px 20px;
  padding: 20px;
  background-color: gray;
  color: #fff;
}
.helptext a {
  text-decoration: none;
  color: #fff;
}
