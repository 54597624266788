.front .error {
  display: none;
  background-color: red;
  color: #fff;
  font-size: 25px;
  text-align: center;
  padding: 20px;
  margin: 15px;
}
.front .errorHeading {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  text-decoration: none
        ;
}
.front .errorLink {
  color: #fff;
  text-decoration: none
        ;
}
.front .highligt-conference {
  padding: 30px 0 0;
  height: 100%;
  cursor: pointer;
}
.front .highligt-conference .next-conference-headline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 10px;
}
@media (min-width: 800px) {
  .front .highligt-conference .next-conference-headline {
    margin-bottom: 30px;
  }
}
.front .highligt-conference .next-conference-headline p {
  color: white;
  text-transform: uppercase;
  font-size: 22px;
  position: relative;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  margin: 0;
}
.front .highligt-conference .next-conference-headline p:before,
.front .highligt-conference .next-conference-headline p:after {
  content: "";
  display: block;
  width: 200px;
  position: absolute;
  top: 13px;
  height: 2px;
  border-top: 2px solid #c28b30;
  border-bottom: 1px solid #c28b30;
}
.front .highligt-conference .next-conference-headline p:before {
  left: -220px;
}
.front .highligt-conference .next-conference-headline p:after {
  right: -220px;
}
.front .highligt-conference .conference-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(100% - 28px - 10px);
}
@media (min-width: 800px) {
  .front .highligt-conference .conference-details {
    height: calc(100% - 28px - 30px - 20px);
  }
}
.front .highligt-conference .conference-details .conference-image {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 25%;
          flex: 0 1 25%;
  width: 100%;
  background-image: url("https://picsum.photos/600/200");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: none;
  margin-left: 80px;
}
@media (min-width: 800px) {
  .front .highligt-conference .conference-details .conference-image {
    display: block;
  }
}
.front .highligt-conference .conference-details .conference-info {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 75%;
          flex: 1 1 75%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 800px) {
  .front .highligt-conference .conference-details .conference-info {
    padding-left: 30px;
  }
}
.front .highligt-conference .conference-details .conference-info .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  text-align: center;
}
@media (min-width: 800px) {
  .front .highligt-conference .conference-details .conference-info .title-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: left;
  }
}
.front .highligt-conference .conference-details .conference-info .title-container .conference-title {
  text-transform: uppercase;
  margin: 0;
  font-size: 20px;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
}
.front .highligt-conference .conference-details .conference-info .title-container .conference-date {
  font-size: 16px;
  color: white;
  margin: 0;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
}
.front .highligt-conference .conference-details .conference-info .conference-information-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 800px) {
  .front .highligt-conference .conference-details .conference-info .conference-information-container {
    margin-right: 80px;
  }
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .details-container {
  display: none;
  -webkit-box-flex: 2;
      -ms-flex: 2 1 70%;
          flex: 2 1 70%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 16px;
}
@media (min-width: 800px) {
  .front .highligt-conference .conference-details .conference-info .conference-information-container .details-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .details-container .conference-info-details {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 50%;
          flex: 0 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 20px;
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .details-container .conference-info-details .details-title {
  font-weight: bold;
  color: #c28b30;
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .details-container .conference-info-details p {
  margin: 0;
  color: white;
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .details-container .conference-info-details p:first-child {
  margin-right: 15px;
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .details-container .conference-info-details.host-details {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .details-container .conference-info-details.host-details .host-logo {
  background-color: #fff;
  max-height: 35px;
  max-width: 150px;
  margin-bottom: 10px;
  padding: 5px;
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .call-to-action {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  height: 40px;
}
@media (min-width: 800px) {
  .front .highligt-conference .conference-details .conference-info .conference-information-container .call-to-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    bottom: 0px;
  }
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .call-to-action a {
  text-decoration: none;
  background-color: #c28b30;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .call-to-action a:hover {
  background-color: #d4a351;
}
.front .highligt-conference .conference-details .conference-info .conference-information-container .call-to-action a p {
  margin: 0;
  width: 100%;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  color: white;
  text-align: center;
}
@media (min-width: 800px) {
  .front .highligt-conference .conference-details .conference-info .conference-information-container .call-to-action a {
    max-width: 120px;
  }
}
.front .more-conferences-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.front .more-conferences-link a {
  padding: 5px 30px;
  background-color: #c28b30;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.front .more-conferences-link a:hover {
  background-color: #d4a351;
}
