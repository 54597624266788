.conference-sidebar {
  padding: 15px;
}
@media (min-width: 800px) {
  .conference-sidebar {
    position: relative;
    top: -40px;
    padding-top: 40px;
    background-color: #eeeeee;
  }
}
.conference-sidebar .hideThis {
  display: none;
}
.conference-sidebar .sidebar-part {
  margin-bottom: 15px;
}
.conference-sidebar .sidebar-part .heading {
  color: #c28b30;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid #c28b30;
  margin-bottom: 10px;
  margin-top: 0px;
}
.conference-sidebar .sidebar-part .small-text {
  margin-top: 3px;
  font-size: 16px;
  font-style: italic;
}
.conference-sidebar .sidebar-part p {
  margin: 0px;
}
.conference-sidebar .sidebar-part.price-details .prices .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.conference-sidebar .sidebar-part.price-details .prices .price .price-number {
  text-align: right;
}
.conference-sidebar .sidebar-part.partners .partner-container .partner {
  background-color: #fff;
  width: 100%;
  margin: 2px;
  padding: 5px 4px 5px 4px;
  height: 70px;
}
.conference-sidebar .sidebar-part.partners .partner-container .partner a {
  display: block;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.conference-sidebar .sidebar-part.partners .partner-container .partner a img {
  display: block;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.conference-sidebar .sidebar-part.partners:after {
  content: "";
  clear: both;
  display: table;
  visibility: hidden;
}
.conference-sidebar .sidebar-part.participating-companies ul,
.conference-sidebar .sidebar-part.participating-companies li {
  margin: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  list-style: none;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
}
.conference-sidebar .sidebar-part.contact-information .single-person {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
}
.conference-sidebar .sidebar-part.contact-information .single-person .contact-image {
  width: 80px;
  height: 87px;
  background-size: cover;
  background-repeat: no-repeat;
}
.conference-sidebar .sidebar-part.contact-information .single-person .contact-image.nanna {
  background-image: url(/static/b5d12001810afdc7a4634770d8c1b7de.png);
}
.conference-sidebar .sidebar-part.contact-information .single-person .contact-image.adnan {
  background-image: url(/static/ec92b9e31ee121d432371ef07026e4f3.png);
}
.conference-sidebar .sidebar-part.contact-information .single-person .contact-image.elisabeth {
  background-image: url(/static/9c014efc83c3848a0db1aff2d88fa536.png);
}
.conference-sidebar .sidebar-part.contact-information .single-person .contact-info {
  margin-left: 10px;
}
.conference-sidebar .sidebar-part.contact-information .single-person .contact-info a {
  color: #c28b30;
  text-decoration: none;
}
.conference-sidebar .share-button-container {
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: 50px;
  text-align: center;
}
.conference-sidebar .share-button {
  position: flex;
  width: 37px;
  height: 37px;
  float: left;
  margin: 0px 5px 0px 0px;
  background-color: #765987;
  text-align: center;
}
.conference-sidebar .button {
  cursor: pointer;
}
