.estate-more-club-sign-up {
  margin-top: 50px;
  margin: 50px 5% 0;
}
@media (min-width: 800px) {
  .estate-more-club-sign-up {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 50px 0 0;
  }
}
.estate-more-club-sign-up .sidebar {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 30%;
          flex: 1 0 30%;
}
@media (min-width: 800px) {
  .estate-more-club-sign-up .sidebar {
    padding-left: 50px;
  }
}
.estate-more-club-sign-up .main {
  -webkit-box-flex: 2;
      -ms-flex: 2 1 100%;
          flex: 2 1 100%;
}
.estate-more-club-sign-up .main .form-header {
  background-color: #797979;
  text-align: center;
  height: 30px;
}
.estate-more-club-sign-up .main .form-header p {
  color: white;
  text-transform: uppercase;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  line-height: 30px;
}
.estate-more-club-sign-up .main .sign-up-form-container {
  background-color: #eeeeee;
  padding: 10px;
}
.estate-more-club-sign-up .main .sign-up-form-container h4 {
  color: #1b1b1b;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.estate-more-club-sign-up .main .sign-up-form-container .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 800px) {
  .estate-more-club-sign-up .main .sign-up-form-container .row {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}
.estate-more-club-sign-up .main .sign-up-form-container .row .field {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  margin-bottom: 7px;
}
@media (min-width: 800px) {
  .estate-more-club-sign-up .main .sign-up-form-container .row .field {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
  }
  .estate-more-club-sign-up .main .sign-up-form-container .row .field:not(:first-child) {
    margin-left: 10px;
  }
  .estate-more-club-sign-up .main .sign-up-form-container .row .field:not(:last-child) {
    margin-right: 10px;
  }
}
.estate-more-club-sign-up .main .sign-up-form-container .row .field input,
.estate-more-club-sign-up .main .sign-up-form-container .row .field select {
  width: 100%;
  border: 1px solid #bab7b9;
  padding: 4px;
}
.estate-more-club-sign-up .main .sign-up-form-container .row .field label {
  display: block;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
  font-size: 0.9em;
  color: #1b1b1b;
}
.estate-more-club-sign-up .main .more-club-container .more-club-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 50px;
}
.estate-more-club-sign-up .main .more-club-container .more-club-layout .more-club-content {
  -webkit-box-flex: 2;
      -ms-flex: 2 1 50%;
          flex: 2 1 50%;
}
.estate-more-club-sign-up .main .more-club-container .more-club-layout .more-club-content .links {
  padding-left: 40px;
  margin-bottom: 15px;
}
.estate-more-club-sign-up .main .more-club-container .more-club-layout .more-club-content .links > p {
  margin: 0;
}
.estate-more-club-sign-up .main .more-club-container .more-club-layout .more-club-content .links > p a {
  color: #c28b30;
  text-decoration: none;
}
.estate-more-club-sign-up .main .more-club-container .more-club-layout .more-club-content label {
  display: block;
}
.estate-more-club-sign-up .main .more-club-container .more-club-layout .more-club-card {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.estate-more-club-sign-up .main .more-club-container .more-club-layout .more-club-card .more-club-card-image {
  width: 200px;
  height: 122px;
  background-image: url(/static/0df29213c36cac6ce8d6154775063734.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
}
.estate-more-club-sign-up .main .form-submit-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.estate-more-club-sign-up .main .form-submit-buttons .submit-button {
  background-color: #c28b30;
  color: white;
  padding: 5px 25px;
}
.estate-more-club-sign-up .main .form-submit-buttons .submit-button:hover {
  background-color: #d4a351;
  cursor: pointer;
}
.estate-more-club-sign-up .main .form-submit-buttons .secondary-button {
  background-color: white;
  color: #1b1b1b;
  padding: 5px 15px;
  border: 1px solid #bab7b9;
  margin-right: 15px;
}
.estate-more-club-sign-up .main .form-submit-buttons .secondary-button:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
