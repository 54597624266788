.confirmation-page {
  min-height: 50vh;
}
.webinar-button-container {
  margin-bottom: 300px;
  height: 300px;
}
.webinar-button-container .webinar-button {
  background-color: #c28b30;
  margin: auto;
  text-align: center;
  padding: 10px;
  border: 0px #000 solid;
  width: 80%;
  cursor: pointer;
}
.webinar-button-container .webinar-button .webinar-link {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
}
.webinar-button-container .webinar-alt-link {
  background-color: #c28b30;
  margin: auto;
  text-align: center;
  padding: 10px;
  border: 0px #000 solid;
  width: 80%;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
}
