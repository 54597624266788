.speakers .speaker {
  margin-bottom: 50px;
}
.speakers .speaker .description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.speakers .speaker .description .speaker-image {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 20%;
          flex: 1 1 20%;
}
.speakers .speaker .description .speaker-image img {
  width: 100%;
  height: auto;
}
.speakers .speaker .description .speaker-description {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 80%;
          flex: 1 1 80%;
  margin: 0 10px 10px;
}
