.conference-download-item {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 35px;
  position: relative;
}
@media (min-width: 800px) {
  .conference-download-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.conference-download-item:after {
  content: "";
  width: 50%;
  height: 2px;
  background-color: white;
  border: 1px solid #c28b30;
  border-left-width: 0;
  border-right-width: 0;
  position: absolute;
  bottom: -5px;
  left: 25%;
}
.conference-download-item:first-of-type:before {
  content: "";
  width: 50%;
  height: 2px;
  background-color: white;
  border: 1px solid #c28b30;
  border-left-width: 0;
  border-right-width: 0;
  position: absolute;
  top: -25px;
  left: 25%;
}
.conference-download-item .download-container {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  margin: 0 20px;
}
.conference-download-item .download-container .downloads {
  margin-bottom: 30px;
}
.conference-download-item .download-container .downloads .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.conference-download-item .download-container .downloads .links p {
  margin-right: 15px;
}
.conference-download-item .download-container .downloads .link a {
  background-color: #000;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
}
.conference-download-item .download-container .uploads .upload-links {
  text-align: justify;
}
.conference-download-item .download-container .download-title {
  padding: 2px 5px;
  background-color: #c28b30;
  color: white;
  display: inline;
}
.conference-download-item .download-conference-card {
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  margin: 15px 20px;
}
@media (min-width: 800px) {
  .conference-download-item .download-conference-card {
    margin-right: 150px;
    max-width: 50%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .conference-download-item .download-conference-card:first-child {
    margin-left: 0;
  }
  .conference-download-item .download-conference-card:last-child {
    margin-right: 0;
  }
}
.conference-download-item .download-conference-card .conference-image {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 45%;
          flex: 1 1 45%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.conference-download-item .download-conference-card .conference-info {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 35%;
          flex: 1 1 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5px;
}
.conference-download-item .download-conference-card .conference-info .conference-date {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.conference-download-item .download-conference-card .conference-info .title-container .conference-title {
  text-transform: uppercase;
  margin: 0;
  font-size: 14px;
}
.conference-download-item .download-conference-card .conference-info .title-container .conference-subtitle {
  margin: 0;
  font-size: 14px;
}
.conference-download-item .download-conference-card .details-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 10px;
}
.conference-download-item .download-conference-card .details-container .conference-details {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 50%;
          flex: 0 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.conference-download-item .download-conference-card .details-container .conference-details .details-title {
  font-weight: bold;
}
.conference-download-item .download-conference-card .details-container .conference-details p {
  margin: 0;
}
.conference-download-item .download-conference-card .details-container .conference-details p:first-child {
  margin-right: 15px;
}
