.programme {
  margin-top: 30px;
}
.programme .programme-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.programme .programme-item .time {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 50px;
          flex: 1 0 50px;
}
.programme .programme-item .time p {
  margin: 0;
}
.programme .programme-item .programme-item-info {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  margin-bottom: 25px;
}
.programme .programme-item .programme-item-info .title {
  margin: 0;
  color: #1b1b1b;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.programme .programme-item .programme-item-info .description {
  margin: 0;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
  white-space: pre-line;
}
.programme .programme-item .programme-item-info .speaker {
  margin: 0;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
  font-style: italic;
}
