.footer {
  background-color: black;
  padding: 50px 0;
  width: 100vw;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-top: 50px;
}
@media (min-width: 1200px) {
  .footer {
    width: calc(100% +  100px * 2);
    margin-left: -100px;
  }
}
.footer .footer-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 800px) {
  .footer .footer-content {
    width: calc(100% -  100px * 2);
    left: 100px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
  }
}
.footer .footer-content .company-information {
  width: 350px;
}
.footer .footer-content .company-information p,
.footer .footer-content .company-information a {
  color: white;
  text-decoration: none;
  font-size: 15px;
  margin: 5px auto;
}
.footer .footer-content .company-information .logo-container {
  width: 190px;
  height: 30px;
}
