.header {
  height: 70px;
}
@media (min-width: 800px) {
  .header {
    height: 110px;
  }
}
.header .error {
  background-color: red;
  color: #fff;
  font-size: 25px;
  text-align: center;
  padding: 20px;
  margin: 15px;
}
.header .errorHeading {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  text-decoration: none
        ;
}
.header .errorLink {
  color: #fff;
  text-decoration: none
        ;
}
.header .header-content {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 20px;
}
@media (min-width: 800px) {
  .header .header-content {
    padding: 0;
  }
}
.header .header-content .page-logo {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 40%;
          flex: 0 1 40%;
  height: 100%;
}
@media (min-width: 800px) {
  .header .header-content .page-logo {
    -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
  }
}
.header .header-content .burger-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 800px) {
  .header .header-content .burger-menu {
    display: none;
  }
}
.header .header-content nav {
  display: none;
}
@media (max-width: 800px) {
  .header .header-content nav {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #000000;
    z-index: 1;
  }
  .header .header-content nav.open {
    display: block;
  }
}
@media (min-width: 800px) {
  .header .header-content nav {
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 4 60%;
            flex: 0 4 60%;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 1200px) {
  .header .header-content nav {
    -ms-flex-preferred-size: 65%;
        flex-basis: 65%;
  }
}
.header .header-content nav p {
  cursor: pointer;
  text-align: center;
  color: white;
}
@media (min-width: 800px) {
  .header .header-content nav p {
    text-align: right;
    color: #1b1b1b;
    margin-right: 15px;
  }
}
.header .header-content nav a {
  color: inherit;
  text-decoration: none;
}
