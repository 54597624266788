.about-page {
  display: block;
}
@media (min-width: 800px) {
  .about-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.about-page .text {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 70%;
          flex: 1 1 70%;
  margin: 0 15px;
}
@media (min-width: 800px) {
  .about-page .text {
    margin: 0 50px 0 0;
  }
}
.about-page .text h3 {
  margin-bottom: 0;
}
.about-page .text h3 + p {
  margin-top: 0;
}
.about-page .sidebar {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
}
