.my-page .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.my-page .header .log-out-link {
  margin-left: 15px;
  border: 1px solid #bab7b9;
  padding: 1px 5px;
  cursor: pointer;
}
.my-page .header .log-out-link:hover {
  border-color: #797979;
}
