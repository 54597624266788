.conference-page .highligt-conference {
  height: 100%;
}
.conference-page .highligt-conference .conference-page-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  font-size: 25px;
  margin-bottom: 10px;
}
@media (min-width: 800px) {
  .conference-page .highligt-conference .conference-page-header {
    font-size: 40px;
  }
}
.conference-page .highligt-conference .conference-page-header p {
  color: #c28b30;
  position: relative;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
  font-weight: 800;
  margin: 0;
}
.conference-page .highligt-conference .conference-page-header p:before,
.conference-page .highligt-conference .conference-page-header p:after {
  content: "";
  display: block;
  width: 200px;
  position: absolute;
  top: 13px;
  height: 2px;
  border-top: 2px solid #c28b30;
  border-bottom: 1px solid #c28b30;
}
.conference-page .highligt-conference .conference-page-header p:before {
  left: -220px;
}
.conference-page .highligt-conference .conference-page-header p:after {
  right: -220px;
}
.conference-page .highligt-conference .conference-page-time {
  display: none;
  color: white;
  font-size: 30px;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0;
}
@media (min-width: 800px) {
  .conference-page .highligt-conference .conference-page-time {
    display: block;
  }
}
.conference-page .highligt-conference .call-to-action {
  position: absolute;
  display: block;
  width: 100%;
}
@media (min-width: 800px) {
  .conference-page .highligt-conference .call-to-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    bottom: 0px;
    right: 50px;
  }
}
.conference-page .highligt-conference .call-to-action a {
  cursor: pointer;
  text-decoration: none;
  background-color: #c28b30;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.conference-page .highligt-conference .call-to-action a:hover {
  background-color: #d4a351;
}
.conference-page .highligt-conference .call-to-action a p {
  margin: 0;
  width: 100%;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  color: white;
  text-align: center;
}
@media (min-width: 800px) {
  .conference-page .highligt-conference .call-to-action a {
    max-width: 120px;
  }
}
.conference-page .conference-main {
  display: block;
}
@media (min-width: 800px) {
  .conference-page .conference-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.conference-page .conference-main .main-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 70%;
          flex: 1 1 70%;
  margin: 0 15px;
}
.conference-page .conference-main .main-content .warning {
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 0px;
  font-weight: 800;
  text-align: center;
  background-color: red;
  padding-top: 10px;
}
.conference-page .conference-main .main-content .warning p {
  color: white;
}
.conference-page .conference-main .main-content .warning a {
  font-size: 25px;
  text-decoration: none;
}
.conference-page .conference-main .main-content .messagebox {
  margin-bottom: 20px;
  margin-top: 0px;
  background-color: #BAB7B9;
  padding: 20px;
}
.conference-page .conference-main .main-content .messageheading {
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
  padding-bottom: 10px;
  font-weight: 800;
}
.conference-page .conference-main .main-content .messageheading p {
  color: white;
}
.conference-page .conference-main .main-content .messageheading a {
  font-size: 25px;
  text-decoration: none;
}
.conference-page .conference-main .main-content .message {
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}
.conference-page .conference-main .main-content .message a {
  font-size: 17px;
  text-decoration: underline;
}
@media (min-width: 800px) {
  .conference-page .conference-main .main-content {
    margin-right: 25px;
    margin: 0 25px 0 0;
  }
}
.conference-page .conference-main .main-content .conference-menu {
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
@media (min-width: 800px) {
  .conference-page .conference-main .main-content .conference-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.conference-page .conference-main .main-content .conference-menu p {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  margin: 0 5px;
  text-align: center;
  font-size: 14px;
}
.conference-page .conference-main .main-content .conference-menu p a {
  padding: 5px 0;
  display: block;
  background-color: #eeeeee;
  font-family: sans-serif;
  font-weight: 100;
  text-decoration: none;
}
.conference-page .conference-main .main-content .conference-menu p a.active {
  background-color: #d5d5d5;
}
.conference-page .conference-main .sidebar {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 30%;
          flex: 1 1 30%;
}
