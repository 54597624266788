.estate-news .top-title {
  text-transform: uppercase;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
}
.estate-news .news-items {
  display: block;
  padding: 0 10px;
}
@media (min-width: 800px) {
  .estate-news .news-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
  }
}
.estate-news .news-items > a {
  text-decoration: none;
}
.estate-news .news-items .singleNews {
  display: block;
  width: 100%;
  margin: 20px 0;
  background-color: #eeeeee;
  border: 1px solid black;
  padding: 10px;
  margin-right: 50px;
}
.estate-news .news-items .singleNews:hover {
  background-color: #d5d5d5;
}
@media (min-width: 800px) {
  .estate-news .news-items .singleNews {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 20%;
            flex: 1 1 20%;
    margin: 5px;
    height: 150px;
  }
}
.estate-news .news-items .singleNews .date {
  text-align: right;
  color: #c28b30;
  margin: 0 0 10px;
}
.estate-news .news-items .singleNews .title {
  width: 100%;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
  margin: 0;
}
