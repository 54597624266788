.loader {
  border: 5px solid #333;
  border-radius: 30px;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 30px;
  -webkit-animation: pulsate 1s ease-out;
          animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
}
