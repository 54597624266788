.newsletter-signup {
  width: 350px;
  max-width: 100%;
}
.newsletter-signup form #mc_embed_signup_scroll {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.newsletter-signup form #mc_embed_signup_scroll .mc-field-group {
  padding-right: 30px;
  -webkit-box-flex: 2;
      -ms-flex: 2 2 100%;
          flex: 2 2 100%;
}
.newsletter-signup form #mc_embed_signup_scroll .mc-field-group input.email {
  width: 100%;
  border: 0;
  padding: 4px;
}
.newsletter-signup form #mc_embed_signup_scroll .subscribe-button {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50px;
          flex: 1 1 50px;
}
.newsletter-signup form #mc_embed_signup_scroll .subscribe-button #mc-embedded-subscribe {
  background-color: #c28b30;
  border: 0;
  outline: 0;
  color: white;
  padding: 5px 10px;
}
