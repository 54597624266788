.conference-search {
  width: 100%;
  background-color: #000000;
  padding: 10px 50px;
  z-index: 1;
}
.conference-search form {
  width: 100%;
  max-width: 1000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.conference-search form .text-input {
  -webkit-box-flex: 2;
      -ms-flex: 2 2 100%;
          flex: 2 2 100%;
  padding: 5px;
}
.conference-search form .search-button {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50px;
          flex: 1 1 50px;
  background-color: orange;
  border: 0;
  outline: 0;
  color: white;
  padding: 5px 10px;
}
