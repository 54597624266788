.conference-page-footer .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.conference-page-footer .links .internal-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.conference-page-footer .links .internal-links a {
  text-decoration: none;
}
.conference-page-footer .links .internal-links .call-to-action {
  background-color: #c28b30;
  color: white;
  padding: 8px 25px;
  text-transform: uppercase;
}
.conference-page-footer .links .internal-links .call-to-action:hover {
  background-color: #d4a351;
  cursor: pointer;
}
.conference-page-footer .links .internal-links .secondary {
  background-color: white;
  color: #1b1b1b;
  padding: 5px 15px;
  border: 1px solid #bab7b9;
  margin-right: 15px;
}
.conference-page-footer .links .internal-links .secondary:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
.conference-page-footer .conference-suggestions2 {
  display: none;
}
@media (min-width: 800px) {
  .conference-page-footer .conference-suggestions2 {
    display: block;
  }
}
.conference-page-footer .more-conferences-header p {
  font-weight: 800;
  color: #000;
  font-size: 16px;
}
