@font-face {
  font-family: 'freightdisp_probook';
  src: url(/static/7764d3fe5a5158f3367faa8e6cb5e353.woff2) format('woff2'), url(/static/f6739dc6152e1eb10455cb0d5bbb6a2f.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'freightdisp_probook';
  src: url(/static/2a1a9471093681b3ee4c9ceac58bc056.woff2) format('woff2'), url(/static/ef43f2a2165f888808ebc19bf5f12d84.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'freightdisp_probook';
  src: url(/static/0d4d5d60f7dda8c26a06b9d87d1efea3.woff2) format('woff2'), url(/static/959278cc9e2ee85d8706d85d21a1cffc.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'freightsans_pro';
  src: url(/static/2fc83382346fcc1c1f0bbfe355c7bf98.woff2) format('woff2'), url(/static/f0402be8511a6038e76fb1586f739980.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'freightsans_pro';
  src: url(/static/8073586aec7aa7c1d3e7ca027b7331a1.woff2) format('woff2'), url(/static/833f6c8fc88b021e22d35d2090a01e7f.woff) format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'freightsans_pro';
  src: url(/static/8cc5809e7b2c2138f14c632e89af9c56.woff2) format('woff2'), url(/static/ec55a65f81d89b928fe4e89ab1a791cd.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #c28b30;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
p,
a {
  color: #1b1b1b;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
}
html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
* {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'freightsans_pro', Arial, Helvetica, sans-serif;
  font-style: normal;
}
.layout {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.layout .content {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  min-height: 70vh;
}
@media (min-width: 800px) {
  .layout .content {
    padding: 0 50px;
  }
}
.layout .content .main {
  min-height: 50vh;
}
