.estate-more-club-confirmation {
  min-height: 50vh;
}
@media (min-width: 800px) {
  .estate-more-club-confirmation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 50px 0 0;
  }
}
.estate-more-club-confirmation .sidebar {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 30%;
          flex: 1 0 30%;
}
@media (min-width: 800px) {
  .estate-more-club-confirmation .sidebar {
    padding-left: 50px;
  }
}
.estate-more-club-confirmation .main {
  -webkit-box-flex: 2;
      -ms-flex: 2 1 100%;
          flex: 2 1 100%;
}
.estate-more-club-confirmation .main .more-club-logo {
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(/static/e89834dba93f1f15a312835194c15c24.png);
}
