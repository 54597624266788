.logo {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.logo.black {
  background-image: url(/static/04442a1a1cf0574b31f01adc68891b85.png);
}
.logo.white {
  background-image: url(/static/2ef84af176d25cc1a23270db321cd74e.png);
}
