.add-to-calendar {
  position: relative;
}
.add-to-calendar .add-to-calendar-button {
  background-color: white;
  color: #1b1b1b;
  padding: 5px 15px;
  border: 1px solid #bab7b9;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 160px;
}
.add-to-calendar .add-to-calendar-button span {
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
}
.add-to-calendar .add-to-calendar-button:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
.add-to-calendar .add-to-calendar-dropdown {
  position: absolute;
  background-color: white;
  top: 32px;
  -webkit-box-shadow: 0 0 1px rgba(34, 25, 25, 0.4);
          box-shadow: 0 0 1px rgba(34, 25, 25, 0.4);
  z-index: 1;
}
.add-to-calendar .add-to-calendar-dropdown ul {
  margin: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  list-style-type: none;
}
.add-to-calendar .add-to-calendar-dropdown ul li {
  list-style: none;
  padding: 5px 15px;
  background-color: white;
  cursor: pointer;
}
.add-to-calendar .add-to-calendar-dropdown ul li:hover {
  background-color: #e6e6e6;
}
.add-to-calendar .add-to-calendar-dropdown ul li a {
  text-decoration: none;
}
