.my-page-content .logged-in-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.my-page-content .logged-in-options .conference-type-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.my-page-content .logged-in-options .conference-type-selector > p {
  padding: 5px 10px;
  border: 1px solid #bab7b9;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
}
.my-page-content .logged-in-options .conference-type-selector > p:first-of-type {
  border-right-width: 0;
}
.my-page-content .logged-in-options .conference-type-selector > p:hover {
  background-color: #e6e6e6;
}
.my-page-content .logged-in-options .conference-type-selector > p:not(.selected) {
  cursor: pointer;
}
.my-page-content .logged-in-options .conference-type-selector > p.selected {
  background-color: #c28b30;
  color: white;
}
.my-page-content .conference-download-items {
  margin-top: 50px;
}
.my-page-content .load-more-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.my-page-content .load-more-button-container .load-more-button {
  background-color: white;
  color: #1b1b1b;
  padding: 5px 15px;
  border: 1px solid #bab7b9;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 200px;
  text-align: center;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
}
.my-page-content .load-more-button-container .load-more-button:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
