.conference-archive .highligt-conference {
  height: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.conference-archive .highligt-conference .archive-header {
  font-size: 25px;
  color: #c28b30;
  font-weight: bold;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
@media (min-width: 800px) {
  .conference-archive .highligt-conference .archive-header {
    font-size: 40px;
  }
}
.conference-archive .desc {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #BAB7B9;
  color: #fff;
  text-align: center;
}
