.sign-up {
  margin-top: 50px;
}
.sign-up .form-header {
  background-color: #797979;
  text-align: center;
  height: 30px;
}
.sign-up .form-header p {
  color: white;
  text-transform: uppercase;
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  line-height: 30px;
}
.sign-up .sign-up-form-container {
  background-color: #eeeeee;
  padding: 10px;
}
.sign-up .sign-up-form-container h4 {
  color: #1b1b1b;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.sign-up .sign-up-form-container .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 800px) {
  .sign-up .sign-up-form-container .row {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}
.sign-up .sign-up-form-container .row .field {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  margin-bottom: 7px;
}
@media (min-width: 800px) {
  .sign-up .sign-up-form-container .row .field {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
  }
  .sign-up .sign-up-form-container .row .field:not(:first-child) {
    margin-left: 10px;
  }
  .sign-up .sign-up-form-container .row .field:not(:last-child) {
    margin-right: 10px;
  }
}
.sign-up .sign-up-form-container .row .field input,
.sign-up .sign-up-form-container .row .field select {
  width: 100%;
  border: 1px solid #bab7b9;
  padding: 4px;
}
.sign-up .sign-up-form-container .row .field label {
  display: block;
  font-family: 'freightdisp_probook', 'Times New Roman', Times, serif;
  font-size: 0.9em;
  color: #1b1b1b;
}
.sign-up .sign-up-form-container .row .message {
  background-color: #D4A351;
  color: #fff;
  width: 100%;
  display: none;
  padding: 5px;
  margin: 10px 0px 10px 0px;
}
.sign-up .more-club-container .more-club-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 50px;
}
.sign-up .more-club-container .more-club-layout .more-club-content {
  -webkit-box-flex: 2;
      -ms-flex: 2 1 50%;
          flex: 2 1 50%;
}
.sign-up .more-club-container .more-club-layout .more-club-content .links {
  padding-left: 40px;
  margin-bottom: 15px;
}
.sign-up .more-club-container .more-club-layout .more-club-content .links > p {
  margin: 0;
}
.sign-up .more-club-container .more-club-layout .more-club-content .links > p a {
  color: #c28b30;
  text-decoration: none;
}
.sign-up .more-club-container .more-club-layout .more-club-content label {
  display: block;
}
.sign-up .more-club-container .more-club-layout .more-club-card {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.sign-up .more-club-container .more-club-layout .more-club-card .more-club-logo {
  width: 150px;
  height: 150px;
  background-image: url(/static/e89834dba93f1f15a312835194c15c24.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
}
.sign-up .bottom-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.sign-up .bottom-buttons .form-submit-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.sign-up .bottom-buttons .form-submit-buttons .submit-button {
  background-color: #c28b30;
  color: white;
  padding: 8px 25px;
  text-transform: uppercase;
}
.sign-up .bottom-buttons .form-submit-buttons .submit-button:hover {
  background-color: #d4a351;
  cursor: pointer;
}
.sign-up .bottom-buttons .form-submit-buttons .submit-button_wait {
  display: none;
  background-color: #c28b30;
  color: white;
  padding: 8px 25px;
  text-transform: uppercase;
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAPIAANSjUQAAAKF8PTcqFQAAAFE+H2xTKXldLiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==);
  background-position-y: center;
  background-position-x: 10px;
  background-repeat: no-repeat;
  padding-left: 35px;
  width: 115px;
}
.sign-up .bottom-buttons .form-submit-buttons .secondary-button {
  background-color: white;
  color: #1b1b1b;
  padding: 5px 15px;
  border: 1px solid #bab7b9;
  margin-right: 15px;
}
.sign-up .bottom-buttons .form-submit-buttons .secondary-button:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
.sign-up .conditions {
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}
.sign-up .conditions-link {
  font-family: "freightsans_pro", Arial, Helvetica, sans-serif;
  text-decoration: underline;
  cursor: pointer;
}
.sign-up .terms {
  display: none;
}
.sign-up .notSeen {
  color: #fff;
}
