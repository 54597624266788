.about-sidebar .contact-information {
  margin-top: 25px;
}
.about-sidebar .contact-information .single-person {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
}
.about-sidebar .contact-information .single-person .contact-image {
  width: 80px;
  height: 87px;
  background-size: cover;
  background-repeat: no-repeat;
}
.about-sidebar .contact-information .single-person .contact-image.nanna {
  background-image: url(/static/b5d12001810afdc7a4634770d8c1b7de.png);
}
.about-sidebar .contact-information .single-person .contact-image.adnan {
  background-image: url(/static/ec92b9e31ee121d432371ef07026e4f3.png);
}
.about-sidebar .contact-information .single-person .contact-image.kamilla {
  background-image: url(/static/f9cbaaa95d5c5c5057614245b9fb7256.jpg);
}
.about-sidebar .contact-information .single-person .contact-info {
  margin-left: 10px;
  -ms-flex-preferred-size: 70%;
      flex-basis: 70%;
}
.about-sidebar .contact-information .single-person .contact-info p {
  margin: 0;
}
.about-sidebar .contact-information .single-person .contact-info a {
  color: #c28b30;
  text-decoration: none;
}
